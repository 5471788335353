<template>
  <div class="adver-continer">
    <div class="adver-but">
       <div class="but-right">
          <el-button size="mini" type="primary" @click="addmoudel()">新增模块</el-button>
       </div>
    </div>
    <div class="adver-conter">
       <el-table
    :data="tableData"
    border
    style="width: 100%">
    <el-table-column
      fixed
      prop="code"
      label="编号"
      width="150">
    </el-table-column>
    <el-table-column
      prop="name"
      label="模块名称"
      width="150">
    </el-table-column>
    <el-table-column
      prop="logoFileUrl"
      label="模块Logo"
      width="150">
      <template slot-scope="scope">
                <img class="lable-img" :src="scope.row.logoFileUrl" alt="">
              </template>
    </el-table-column>
    <el-table-column
      prop="tip"
      label="模块提示语"
      width="200">
    </el-table-column>
   <el-table-column prop="enable" label="隐藏/显示" width="85">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.enable"
              active-color="#13ce66"
              inactive-color="#ff4949"
               @change="handleStatusChange(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
    <el-table-column
      fixed="right"
      label="操作"
    >
      <template slot-scope="scope">
        <el-button @click="handleClick(scope.row)" type="text" size="small">编辑</el-button>
        <el-button type="text" size="small" @click="delet(scope.row)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
    </div>
  </div>
</template>

<script>
import {GetList,GetDetail,SetEnable,getDetail,Delete,AddOrEdit} from '../../api/moudels'
  export default {
    data(){
      return{
        tableData:[],//数据列表
      }
    },
    methods:{
      //获取数据列表
        mGetList(){
          GetList({}).then(res=>{
            console.log(res)
            if(res.code===10000){
              this.tableData=res.data
            }
          }).catch(err=>{
            console.log(err)
          })
        },
          //编辑
        handleClick(row){
            this.$router.push({ path: '/compile',query:{id:row.moduleManageId,num:1}})
        },
        //新增
        addmoudel(){
              this.$router.push({ path: '/compile',query:{num:0}})
        },
        //删除
        delet(row){
           this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            let data={
                id:row.moduleManageId
              }
              Delete(data).then(res=>{
                console.log(res)
                if(res.code===10000){
                    this.mGetList()
                }
              })
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
              // console.log(row)
            
        },
        //设置显示隐藏
        handleStatusChange(row){
            console.log(row)
            let data={
              id:row.moduleManageId,
              enable:row.enable == true ? 1:0
            }
            SetEnable(data).then(res=>{
              console.log(res)
              if(res.code===10000){
                this.$message('执行成功')
              }
            }).catch(err=>{
              console.log(err)
            })
        }
    },
    created(){
        this.mGetList()
    }
  }
</script>

<style  scoped>
.adver-conter{
  margin-top: 20px;
  /* overflow: hidden; */
}
.adver-but{
  width: 100%;
  height:50px;
  background: white;
  margin-top: 20px;
  overflow: hidden;

}
.but-right{
margin-left:90% ;
margin-top: 10px;
}
.lable-img{
  width: 80px;
  height: 80px;
}
</style>