import { render, staticRenderFns } from "./moudels.vue?vue&type=template&id=cadf18ac&scoped=true&"
import script from "./moudels.vue?vue&type=script&lang=js&"
export * from "./moudels.vue?vue&type=script&lang=js&"
import style0 from "./moudels.vue?vue&type=style&index=0&id=cadf18ac&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cadf18ac",
  null
  
)

export default component.exports